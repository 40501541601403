// Based on: https://github.com/angular/material2/blob/master/guides/theming.md
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the default theme (same as the example above).
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent:  mat.define-palette(mat.$blue-palette, A200, A100, A400);
$candy-app-theme:   mat.define-light-theme($candy-app-primary, $candy-app-accent);

// Include the default theme styles.
@include mat.all-component-themes($candy-app-theme);


// Define an alternate dark theme.
$dark-primary: mat.define-palette(mat.$blue-grey-palette);
$dark-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn:    mat.define-palette(mat.$deep-orange-palette);
$dark-theme:   mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.unicorn-dark-theme {
  @include mat.all-component-themes($dark-theme);
}

.material-icons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}


.vm_type_selection .mat-input-infix {
    padding-top: 1em;
    padding-bottom: 2px;
}
