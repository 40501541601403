/* You can add global styles to this file, and also import other style files */
/* full width mat-form-field */
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Sticky Footer - https://css-tricks.com/couple-takes-sticky-footer/ */
* {
    margin: 0;
}

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.app-root {
    display: block;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.wrapper {
    flex: 1;
}

footer {
    flex-shrink: 0;
}

/* End Sticky Footer */

.custom-expansion-header > .mat-content {
    justify-content: flex-start;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
    flex: 1 1 auto;
}

.grafana-expansion-panel .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 1px !important;
}

.green-progress .mat-progress-bar-fill::after {
    background-color: green !important;
}

.flex-spacer {
    flex: 1 1 auto;
}
